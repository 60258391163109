.project-card {
  font-size: 1rem;
  padding: 1rem;
}
.project-card {
  flex-basis: calc(33.33% - 20px);
  margin-right: 20px;
  max-width: 100%;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card .btn {
  display: block;
  width: 80%;
  margin: 2rem auto;
}
.project-heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}
.project-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
}

.project-card {
  background: #1a1919;
  transition: all 0.2s linear;
  padding: 1.2rem 1rem;
}
.project-card:hover {
  transform: scale(1.1);
}
.project-card img {
  width: 100%;
}

.project-title {
  color: #fff;
  padding: 1rem 0;
}
.pro-details p {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: justify;
}
.pro-btns {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.pro-btns .btn {
  padding: 0.5rem 1rem;
}
@media screen and (max-width: 740px) {
  .project-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}
