@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}
body {
  background-color: #000;
}
h1,
h4,
p,
a {
  text-decoration: none;
  color: #fff;
}
ul {
  list-style-type: none;
}

/* projects  */
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.btn-light {
  background: transparent;
  color: #fff;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #999999, #7c7c7c);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #4b4b4b, #4d4d4d);
}
