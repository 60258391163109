.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10;
}
.header-text {
  transition: all 0.2s ease-out;
}
.header-text:hover {
  transform: scale(1.1);
  color: #9e9d9daf;
}
.header-bg {
  background-color: rgb(0, 0, 0, 0.85);
  transition: 0.5s;
}
.nav-menu {
  display: flex;
}
.nav-menu li {
  padding: 0 1rem;
  transition: all 0.2s ease-out;
}
.nav-menu li:hover {
  transform: scale(1.2);
  color: #49494966;
}
.nav-menu li a {
  font-size: 1.2rem;
  font-weight: 500;
}
.burger {
  display: none;
}
@media screen and (max-width: 1040px) {
  .nav-menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-menu li {
    padding: 1rem 0;
  }
  .nav-menu li a {
    font-size: 2rem;
  }
  .burger {
    display: initial;
  }
}
